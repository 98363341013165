<template>
	<div>
		<a-layout>
			<a-layout-sider style="background: #FFFFFF;overflow: hidden;min-height: calc(100vh - 100px); " width="200px">
				<div style="padding: 20px 20px;">
					<div v-for="(item, index) in menus" :key="index" @click="goToUrl(item.link)" class="second-menu-item " :class="{on:getSelectKey.indexOf(item.key) > -1}">
						<i class="iconfont ft14" :class="item.icon"></i>
						<span class="ft14 ml10">{{item.name}}</span>
					</div>
				</div>
			</a-layout-sider>
			<a-layout-content style="position: relative; margin: 0px 20px; background: #F7F8FA; min-height: calc(100vh - 100px);">
				<router-view></router-view>
			</a-layout-content>
		</a-layout>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				menus: [
					{
						name: '代理后台设置',
						icon: 'iconshezhi1',
						key: 'agent_index',
						link: '/agent'
					},
					
					{
						name: '代理分类',
						icon: 'iconcunchu',
						key: 'agent_type',
						link: '/agent/type'
					},
					{
						name: '代理管理',
						icon: 'iconmessage',
						key: 'agent_manage',
						link: '/agent/manage'
					},
					{
						name: '代理提现管理',
						icon: 'iconzhifushezhi',
						key: 'agent_withdrawal',
						link: '/agent/withdrawal'
					}
				],
			}
		},
		methods: {
			goToUrl(url) {
				// this.refreshPage(this.menus[index].link);
				this.$router.push({path: url})
			},
			addAct(){
				this.$router.push('/application/edit');
			},
		}
	}
</script>

<style>
	.merchant-layout-audit-num{
		background: #FF6600;
		border-radius: 8px;
		padding: 0px 5px 0px 5px;
		
		font-size: 12px;
		font-weight: 400;
		color: #FFFFFF;
	}
	.fade-right-enter-to,
	.fade-right-leave-from{
		opacity: 1;
		transform: none;
	}
	
	.fade-right-enter-active,
	.fade-right-leave-active
	{
		transition: all 1s;
	}
	
	.fade-right-enter-from,
	.fade-right-leave-to{
		opacity: 0;
		transform: translateX(20px);
	}
</style>
